<template>
  <div id="app" style="min-width: 1200px">
    <router-view></router-view>
  </div>
</template>
<script>


export default {
  name: 'App',
}
</script>

<style>

.content{
  width: 1200px;
  margin: auto;
}
img{
  width: 100%;
  height: 100%;
}

</style>
